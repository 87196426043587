import { MenuOutlined } from "@ant-design/icons";
import moment from "moment";

import {
    Button,
    Card,
    Col,
    Dropdown,
    Form,
    Input,
    Menu,
    Row,
    Select,
    Table,
    Modal
} from "antd";
import { useEffect, useState } from "react";
import {
    DateFormat,
    fetchData,
    postData,
    showError,
    toUtc,
} from "../../utils";
import { useBlockScreen, useRoot } from "../../rootContext";

const getColumns = ({ onAction }) => [
    {
        title: "User Name",
        dataIndex: "UserName",
        key: "UserName",
        fixed: "left",
        //sorter: true,
    },
    {
        title: "Email",
        dataIndex: "Email",
        key: "Email",
    },
    {
        title: "Action",
        dataIndex: "Id",
        key: "x",
        render: (x, item) =>
            ActionMenue({
                onClick: (action) => {
                    onAction({ action, item });
                },
                scheduled: item.ScheduledForUpdate,
                isIndividual: true,
            }),
    },
];
const ActionMenue = (props) => {
    return (
        <Dropdown overlay={menu(props)} placement="bottomLeft">
            <Button>
                <MenuOutlined />
            </Button>
        </Dropdown>
    );
};
const menu = (props) => {
    const items = [
        {
            key: "EDIT",
            label: "Edit",
            onClick: (e) => {
                props.onClick(e.key);
            },
        }
    ];
    return <Menu items={items} />;
};
//const userRoleOptions = [];
const ManageUsers = () => {
    const blocker = useBlockScreen();
    const root = useRoot();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [pageData, setPageData] = useState({ data: [], total: 0 });
    const [filterData, setFilterData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [pageInfo, setPageInfo] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [form] = Form.useForm();
    const [userRoleOptions, setUserRoleOptions] = useState([
        { label: "View Pricing", value: "pricing-screen", },
        { label: "Add Product", value: "add-product", },
        { label: "Sync Pricing Qty", value: "sync-pricing-qty", },
        { label: "Export Pricing", value: "export-pricing" },
        { label: "Delete Pricing", value: "delete-pricing" },
        { label: "Edit Pricing", value: "edit-pricing" },
        { label: "Enable Disable Suppliers", value: "enable-disable-suppliers" },
        { label: "Update Best Buy", value: "update-best-buy" }
    ]);
    const [selectedUserClaims, setSelectedUserClaims] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [userClaims, setUserClaims] = useState([]);

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const clearFilter = () => {
        const newFilter = {};
        setFilterData({ ...newFilter });
        getData({ page: 0, size: 10, filter: newFilter });
    };

    const applyFilter = () => {
        getData({ page: 0, size: 10, filter: filterData });
    };

    const getData = ({ page, size, filter }) => {
        fetchData(
            window.SiteBasePath +
            `/api/user?page=${page || 0}&size=${size || 10}
                &username=${filter.username || ""}&email=${filter.email || ""}&sortDesc=${filter.sortDesc || false}`,
            { blocker, root }
        )
            .then((resp) => {
                setPageData({ data: resp.Data, total: resp.Count });

                setSelectedRowKeys([]);
            });
    };
    const showModal = (userId) => {
        fetchData(
            window.SiteBasePath + `/api/user/claims/${userId}`,
            { blocker, root }
        ).then((resp) => {
            console.log(resp);
            setUserClaims(resp);
            setIsModalOpen(true);
        });
        setSelectedUserId(userId);
    };

    const handleOk = () => {
        var url = window.SiteBasePath + `/api/user/assign-claims/${selectedUserId}`;
        postData(
            url,
            { claims: selectedUserClaims },
            { blocker, root }
        ).then((resp) => {
                alert("User updated successfully.");
                setIsModalOpen(false);
            });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleRoleOptionChange = (value) => {
        setUserClaims(value);
        setSelectedUserClaims(value);
    };
    useEffect(() => {
        getData({
            page: pageInfo.current - 1,
            size: pageInfo.pageSize,
            filter: filterData,
        });
    }, [pageInfo]);

    const onAction = ({ action, item, items }) => {
        if (!item && (!items || items.length < 1)) {
            showError("Please select an item first");
            return;
        }
        if (action === "EDIT") {
            showModal(item.Id);
        }
        setSelectedRowKeys([]);
    };

    return (
        <>
            <h4 style={{ margin: "10px 0" }}>Manage Users</h4>
            <Card style={{ marginBottom: "10px" }}>
                <Form form={form} layout="vertical">
                    <Row>
                        <Col span={6} style={{ paddingRight: "10px" }}>
                            <Form.Item label="User Name">
                                <Input
                                    value={filterData.username}
                                    onChange={(e) => {
                                        setFilterData({ ...filterData, username: e.target.value });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6} style={{ paddingRight: "10px" }}>
                            <Form.Item label="Email">
                                <Input
                                    value={filterData.email}
                                    onChange={(e) => {
                                        setFilterData({ ...filterData, email: e.target.value });
                                    }}
                                />
                            </Form.Item>
                        </Col> 
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: "right" }}>
                            <Button onClick={clearFilter}>Reset</Button>
                            <Button onClick={applyFilter}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card style={{ marginBottom: "10px" }}>
                <Table
                    loading={loadingData}
                    rowKey="Id"
                    pagination={{ total: pageData.total }}
                    onChange={(pagination, filters, sorter) => {
                        //sorter = sorter.sorter;
                        sorter.column
                            ? setFilterData({
                                ...filterData,
                                sortPropProduct: sorter.field || "",
                                sortDesc: sorter.order === "descend",
                            })
                            : setFilterData({
                                ...filterData,
                                sortPropProduct: "",
                                sortDesc: false,
                            });

                        setPageInfo({ ...pagination });
                    }}

                    rowSelection={rowSelection}
                    columns={getColumns({ onAction })}
                    dataSource={pageData.data}
                    bordered
                    size="small"
                />
            </Card>
            <Modal title="Edit User" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>User Can:</p>
                <Select
                    mode="multiple"
                    allowClear
                    style={{
                        width: '100%',
                    }}
                    placeholder="Please select"
                    onChange={handleRoleOptionChange}
                    value={userClaims}
                    options={userRoleOptions}
                />
            </Modal>
        </>
    );
};

export default ManageUsers;
