import moment from "moment";
import { useRoot } from "./rootContext";

export const DateFormat = "YYYY-MM-DD";
export const UserDateTimeFormat = "YYYY-MM-DD HH:mm:ss";

export const toUtc = (date, addDay) => {
    if (date) {
        const utc = moment(date).add(new Date().getTimezoneOffset(), "minutes");
        if (addDay) return moment(utc).add(1, "days").format("YYYY-MM-DDTHH:mm:ss");
        return utc.format("YYYY-MM-DDTHH:mm:ss");
    }
};

export const toLocalDateTime = (isoTime) => {
    if (isoTime) {
        return moment.utc(isoTime).local().format(UserDateTimeFormat);
    }
};

export const fetchData = (url, options) => {
    const { blocker, root } = options || {};
    blocker && blocker.block();
    const token = localStorage.getItem("AppAuthToken") || "";
    return new Promise((resolve, reject) => {
        fetch(url, { headers: { Authorization: "bearer " + token } })
            .then(function (resp) {
                if (resp.ok) {
                    resp.json().then(function (data) {
                        resolve(data);
                    });
                } else if (resp.status === 400 || resp.status === 500) {
                    resp.json().then(function (data) {
                        showError(data.message);
                    });
                } else if (resp.status === 401) {
                    root && root.setLogedIn(false);
                    !root && console.warn("root context must be provided");
                }
            })
            .catch((e) => {
                reject(e);
                console.log(e);
                showError(
                    "Something went wrong please contact the system administrator."
                );
            })
            .finally(() => {
                blocker && blocker.unblock();
            });
    });
};

export const postData = (url, data, options) => {
    const { blocker, root } = options || {};
    blocker && blocker.block();
    const token = localStorage.getItem("AppAuthToken") || "";
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
            body: JSON.stringify(data),
        })
            .then(function (resp) {
                if (resp.ok) {
                    resp.json().then(function (data) {
                        resolve(data);
                    });
                } else if (resp.status === 400 || resp.status === 500) {
                    resp.json().then(function (data) {
                        showError(data.message);
                    });
                } else if (resp.status === 401) {
                    root && root.setLogedIn(false);
                    !root && console.warn("root context must be provided");
                }
            })
            .catch((e) => {
                reject(e);
                console.log(e);
                showError(
                    "Something went wrong please contact the system administrator."
                );
            })
            .finally(() => {
                blocker && blocker.unblock();
            });
    });
};

export const claimTypes = {
    PRICING_SCREEN: "pricing-screen",
    ADD_PRODUCT: "add-product",
    SYNC_PRICING: "sync-pricing-qty",
    EXPORT_PRICING: "export-pricing",
    DELETE_PRICING: "delete-pricing",
    EDIT_PRICING: "edit-pricing",
    ENABLE_DISABLE_SUPPLIERS: "enable-disable-suppliers",
    UPDATE_BEST_BUY: "update-best-buy",
    ADMIN: "admin"
}
export var userOptions = {
    userName: null,
    claims: [],
    permissions: {
        viewPricing: false,
        addProduct: false,
        syncPricing: false,
        exportPricing: false,
        deletePricing: false,
        editPricing: false,
        enableDisableSupplier: false,
        updateBestBuy : false
    }
}

export const mapUserPermissions = (userInfo) => {
    var claims = userInfo.claims;
    return {
        viewPricing: hasClaim(claims, claimTypes.PRICING_SCREEN),
        addProduct: hasClaim(claims, claimTypes.ADD_PRODUCT),
        syncPricing: hasClaim(claims, claimTypes.SYNC_PRICING),
        exportPricing: hasClaim(claims, claimTypes.EXPORT_PRICING),
        deletePricing: hasClaim(claims, claimTypes.DELETE_PRICING),
        editPricing: hasClaim(claims, claimTypes.EDIT_PRICING),
        enableDisableSupplier: hasClaim(claims, claimTypes.EDIT_PRICING),
        updateBestBuy: hasClaim(claims, claimTypes.UPDATE_BEST_BUY)
    }
}
const hasClaim = (claims, claimType) => {
    return hasAdminRole(claims) || claims.filter(x => x.Value === claimType).length > 0;
}
const hasAdminRole = (claims) => {
    return claims.filter(x => x.Value === claimTypes.ADMIN).length > 0;
}

export const showError = (msg) => {
    alert(msg);
};

export const showConfirm = (msg, onOk) => {
    if (window.confirm(msg)) {
        onOk && onOk();
    }
};
export const removeByIndex = (str, index) => {
    return str.slice(0, index) + str.slice(index + 1);
};
