import {
    DollarOutlined,
    FileSyncOutlined,
    PlusOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    KeyOutlined,
    DesktopOutlined
} from "@ant-design/icons";
import { Button, Card, Col, Row, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBlockScreen, useRoot, useSetActiveTab } from "../../rootContext";
import { fetchData, postData, showConfirm } from "../../utils";

const Home = () => {
    const blocker = useBlockScreen();
    const root = useRoot();
    const setActiveTab = useSetActiveTab(root);
    const navigate = useNavigate();
    const [checkboxes, setCheckboxes] = useState([]);
    const user = root.user;

    const handleCheckboxChange = (id, status) => {
        showConfirm("Are you sure?", () => {
            postData(
                window.SiteBasePath + "/api/supplier/set-status",
                { supplierId: id, supplierStatus: status },
                { blocker, root }
            ).then((resp) => onSupplierStatusUpdated(resp, id));
        })
    };
    const onSupplierStatusUpdated = (resp, supplierId) => {
        setCheckboxes((prevCheckboxes) => {
            return prevCheckboxes.map((checkbox) => {
                if (checkbox.SupplierId === supplierId) {
                    return {
                        ...checkbox,
                        Enabled: !checkbox.Enabled,
                    };
                }
                return checkbox;
            });
        });
        resp.Message && alert(resp.Message);
    }
    useEffect(() => {
        fetchData(
            window.SiteBasePath + "/api/supplier/all",
            { blocker, root }
        ).then((resp) => {
            console.log(resp.suppliers);
            setCheckboxes(resp.suppliers)
        });
        setActiveTab("");
    }, []);
    return (
        <>
            <h4 style={{ margin: "10px 0px" }}>Dashboard Canadian Portal</h4>
            <Card style={{ marginTop: "10px" }}>
                <Row>
                    {user.permissions.viewPricing &&
                        <Col span={4}>
                            <Button
                                type="red"
                                icon={<DollarOutlined />}
                                size="large"
                                style={{ width: "90%" }}
                                onClick={() => {
                                    navigate("pricing");
                                }}
                            >
                                Pricing
                            </Button>
                        </Col>}
                    <Col span={4} style={{ display: "none" }}>
                        <Button
                            type="violet"
                            icon={<ShoppingCartOutlined />}
                            size="large"
                            style={{ width: "90%" }}
                            onClick={() => {
                                navigate("orders");
                            }}
                        >
                            Orders
                        </Button>
                    </Col>
                    {user.permissions.addProduct &&
                        <Col span={4}>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                size="large"
                                style={{ width: "90%" }}
                                onClick={() => {
                                    navigate("products/add");
                                }}
                            >
                                Add Product
                            </Button>
                        </Col>
                    }
                    {user.permissions.syncPricing &&
                        <Col span={4}>
                            <Button
                                type="orange"
                                icon={<FileSyncOutlined />}
                                size="large"
                                style={{ width: "90%" }}
                                onClick={() => {
                                    fetchData(
                                        window.SiteBasePath +
                                        "/api/pna/pull?pageCount=50&refreshAll=true",
                                        { blocker, root }
                                    ).then((resp) => {
                                        resp.Message && alert(resp.Message);
                                    });
                                }}
                            >
                                Sync Pricing+Qty
                            </Button>
                        </Col>
                    }
                    {user.permissions.updateBestBuy &&
                        <Col span={4}>
                            <Button
                                type="green"
                                icon={<FileSyncOutlined />}
                                size="large"
                                style={{ width: "90%" }}
                                onClick={() => {
                                    fetchData(window.SiteBasePath + "/api/products/update-bb-qty", {
                                        blocker,
                                        root,
                                    }).then((resp) => {
                                        resp.Message && alert(resp.Message);
                                    });
                                }}
                            >
                                Sync Best Buy Qty
                            </Button>
                        </Col>
                    }
                    <Col span={4} style={{ display: "none" }}>
                        <Button
                            type="green"
                            icon={<FileSyncOutlined />}
                            size="large"
                            style={{ width: "90%" }}
                            onClick={() => {
                                fetchData(window.SiteBasePath + "/api/orders/fetch-orders", {
                                    blocker,
                                    root,
                                }).then((resp) => {
                                    resp.Message && alert(resp.Message);
                                });
                            }}
                        >
                            Sync Orders
                        </Button>
                    </Col>
                    {user.UserName === "admin" &&
                        <Col span={4}>
                            <Button
                                type="green"
                                icon={<UserOutlined />}
                                size="large"
                                style={{ width: "90%" }}
                                onClick={() => {
                                    navigate("users/manage");
                                }}
                            >
                                Manage Users
                            </Button>
                        </Col>
                    }
                    <Col span={4}>
                        <Button
                            type="danger"
                            icon={<KeyOutlined />}
                            size="large"
                            style={{ width: "90%" }}
                            onClick={() => {
                                navigate("account/update-password");
                            }}
                        >
                            Update Password
                        </Button>
                    </Col>
                </Row>
            </Card>

            {user.UserName === "admin" &&
                <>
                    <h4 style={{ margin: "10px 0px" }}>Accounts</h4>
                    <Card style={{ marginTop: "10px" }}>
                        <Row>
                            <Col span={4}>
                                <Button
                                    type="primary"
                                    icon={<DesktopOutlined />}
                                    size="large"
                                    style={{ width: "90%" }}
                                    onClick={() => {
                                        navigate("processors/manage");
                                    }}
                                >
                                    Manage Processors
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </>
            }

            {user.permissions.enableDisableSupplier &&
                <>
                    <h4 style={{ margin: "10px 0px" }}>Enable / Disable Suppliers</h4>
                    <Card>
                        <Row>
                            {checkboxes.map((checkbox) => (
                                <Checkbox
                                    key={checkbox.SupplierId}
                                    checked={checkbox.Enabled}
                                    onChange={(e) => handleCheckboxChange(checkbox.SupplierId, e.target.checked)}
                                >
                                    {checkbox.SupplierName}
                                </Checkbox>
                            ))}
                        </Row>
                    </Card>
                </>
            }
        </>
    );
};
export default Home;
