import { UserOutlined } from "@ant-design/icons";
import { Layout, Menu, Spin } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useEffect } from "react";
import { Link, useNavigate, useRoutes } from "react-router-dom";
import Login from "./features/account/Login";
import Home from "./features/home/Home";
import Orders from "./features/orders/Orders";
import Pricing from "./features/pricing/Pricing";
import AddProduct from "./features/product/AddProduct";
import ManageUsers from "./features/users/ManageUsers";
import UpdatePassword from "./features/account/UpdatedPassword";
import ManageProcessors from "./features/processors/ManageProcessors";
import { useAuth, useRoot } from "./rootContext";
const { Header, Content, Footer } = Layout;

const AppRouter = () => {
    let element = useRoutes([
        { exact: true, path: "/", element: <Home /> },
        { path: "products/add", element: <AddProduct /> },
        { path: "pricing/edit-product/:id/:f", element: <AddProduct /> },
        { path: "pricing", element: <Pricing /> },
        { path: "orders", element: <Orders /> },
        { path: "users/manage", element: <ManageUsers /> },
        { path: "account/update-password", element: <UpdatePassword /> },
        { path: "processors/manage", element: <ManageProcessors /> },
    ]);
    return element;
};
const LoginRouter = () => {
    let element = useRoutes([
        { exact: true, path: "/", element: <Login /> },
        // { path: "login", element: <Login /> },
    ]);
    return element;
};
const AppLayout = () => {
    const { activeTab, screenBlocked, logedIn, user } = useRoot();
    const navigate = useNavigate();
    const authHandler = useAuth();
    useEffect(() => {
        if (!logedIn) navigate("");
    }, [logedIn]);
    return (
        <Layout className="layout">
            <Spin
                tip="Loading..."
                wrapperClassName="custom-spin-class"
                spinning={screenBlocked || false}
            >
                {logedIn && (
                    <Header>
                        <div
                            className="logo"
                            onClick={() => {
                                navigate("");
                            }}
                        >
                            Loyalty Sense
                        </div>
                        <Menu
                            theme="dark"
                            mode="horizontal"
                            selectedKeys={[activeTab || ""]}
                        >
                            {user.permissions.viewPricing &&
                                <Menu.Item key="pricing">
                                    <Link to="/pricing">Pricing</Link>
                                </Menu.Item>
                            }
                            {user.permissions.addProduct &&
                                <Menu.Item key="add-product">
                                    <Link to="/products/add">Add Products</Link>
                                </Menu.Item>
                            }
                            <Menu.Item key="orders" style={{ display: "none" }}>
                                <Link to="/orders">Orders</Link>
                            </Menu.Item>
                            <SubMenu
                                key="sub-menu"
                                style={{ marginLeft: "auto" }}
                                title={
                                    <span>
                                        <UserOutlined /> {user.UserName}
                                    </span>
                                }
                            >
                                <Menu.Item
                                    key="logout"
                                    onClick={() => {
                                        authHandler.logout();
                                        navigate("");
                                    }}
                                >
                                    Logout
                                </Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Header>
                )}

                <Content
                    style={{
                        padding: "0 50px",
                        minHeight: "100vh",
                    }}
                >
                    {logedIn ? <AppRouter /> : <LoginRouter />}
                </Content>
                <Footer
                    style={{
                        textAlign: "center",
                    }}
                >
                    Loyalty Sense ©2022
                </Footer>
            </Spin>
        </Layout>
    );
};

export default AppLayout;
