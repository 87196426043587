import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { SelectControl } from "../../controls/SelectControl";
import { useBlockScreen, useRoot, useSetActiveTab } from "../../rootContext";
import { fetchData, postData, removeByIndex, showError } from "../../utils";

const AddProduct = () => {
  const { id, f } = useParams();

  const setActiveTab = useSetActiveTab();
  const blocker = useBlockScreen();
  const root = useRoot();
  const [form, setForm] = useState({});
  const [selectOptions, setSelectOptions] = useState({
    brands: [],
    categories: [],
    platforms: [],
  });
  const navigate = useNavigate();
  const formChange = (e) => {
    updateFormState(e.target.name, e.target.value);
  };
  const updateFormState = (name, value) => {
    setForm({ ...form, [name]: value });
  };
  const addProduct = async (e) => {
    e.preventDefault();
    if (!/^([a-zA-Z0-9._+()=\-\s])*$/.test(form.Sku)) {
      showError("Sku contains some invalid character");
      return;
    }
    if (form.Sku && form.Title && form.Category && form.Brand) {
      postData(
        window.SiteBasePath + `/api/products`,
        { ...form, Id: id || 0 },
        { blocker, root }
      ).then((resp) => {
        setForm({});
        if (f === "p") {
          navigate(-1);
        }
      });
        return;

    }
      showError("All fields required.");
      
  };
    
  const handleItemCreate = (name, item) => {
    const tempOptions = selectOptions[name];
    if (tempOptions.indexOf(item) < 0)
      setSelectOptions({ ...selectOptions, [name]: [...tempOptions, item] });
  };

  const [formA] = Form.useForm();
  useEffect(() => {
    fetchData(
      window.SiteBasePath +
        `/api/Products/master-data?types=brands,categories,platforms`,
      { blocker, root }
    ).then((resp) => {
      setSelectOptions(resp);
      if (id && !isNaN(id) && id > 0) {
        fetchData(window.SiteBasePath + `/api/Products/?id=${id}`, {
          blocker,
          root,
        }).then((resp) => {
          setForm(resp);
        });
      }
    });
  }, []);
  useEffect(() => {
    setActiveTab("add-product");
  }, []);

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: form.Platforms || [],
    options: (selectOptions.platforms || []).map((x) => ({
      value: x.Id,
      label: x.Name,
    })),
    onChange: (newValue) => {
      updateFormState("Platforms", newValue);
      //console.log(newValue);
    },
    placeholder: "Select Platforms...",
    maxTagCount: "responsive",
  };
  return (
    <>
      <Row>
        <Col span={12} offset={6}>
          <h4 style={{ margin: "10px 0" }}>Add Product</h4>
          <Card style={{ marginBottom: "10px" }}>
            <Form form={formA}>
              <Form.Item>
                <SelectControl
                  label="Brand"
                  //onCreateItem={(item) => handleItemCreate("brands", item)}
                  onSelectedItemChange={(item) => {
                    handleItemCreate("brands", item);
                    updateFormState("Brand", item);
                  }}
                  selectedItem={
                    selectOptions.brands.find((x) => {
                      return x === form.Brand;
                    }) || ""
                  }
                  items={selectOptions.brands}
                />
              </Form.Item>
              <Form.Item>
                <SelectControl
                  label="Category"
                  //onCreateItem={(item) => handleItemCreate("categories", item)}
                  onSelectedItemChange={(item) => {
                    handleItemCreate("categories", item);
                    updateFormState("Category", item);
                  }}
                  selectedItem={
                    selectOptions.categories.find((x) => x === form.Category) ||
                    ""
                  }
                  items={selectOptions.categories}
                />
              </Form.Item>
              <Form.Item>
                <label htmlFor="tbTitle">Title</label>
                <Input
                  id="tbTitle"
                  name="Title"
                  type="text"
                  value={form.Title || ""}
                  onChange={formChange}
                />
              </Form.Item>
              <Form.Item
              // name="Sku"
              // rules={[
              //   {
              //     required: true,
              //   },
              //   {
              //     validator: (_, value) => {
              //       console.log({ _, value });
              //       let valid = true;
              //       for (var i = 0; i < value.length; i++) {
              //         if (
              //           "0123456789abcdefghijklmnopqrstuvwxyz-. ".indexOf(
              //             value[i].toLower()
              //           ) < 0
              //         ) {
              //           valid = false;
              //           break;
              //         }
              //       }
              //       console.log({ value, valid });
              //       return valid
              //         ? Promise.resolve()
              //         : Promise.reject(
              //             new Error("Sku contains some invalid character.")
              //           );
              //     },
              //   },
              // ]}
              >
                <label htmlFor="tbSku">Sku</label>
                <Input
                  id="tbSku"
                  name="Sku"
                  type="text"
                  value={form.Sku || ""}
                  //onChange={formChange}
                  onChange={(e) => {
                    //let valid = true;
                    let val = e.target.value;
                    // let len = val.length;
                    // for (var i = 0; i < len; i++) {
                    //   if (
                    //     "0123456789abcdefghijklmnopqrstuvwxyz-. ".indexOf(
                    //       val[i].toLowerCase()
                    //     ) < 0
                    //   ) {
                    //     val = removeByIndex(val, i);
                    //     len = val.length;
                    //   }
                    // }
                      val = val.replace(/[^a-zA-Z0-9._+()=\-\s]/g, "");
                    updateFormState(e.target.name, val);
                  }}
                />
              </Form.Item>
              <Form.Item>
                <label>Platforms</label>
                <Select {...selectProps} />
              </Form.Item>
              <Button onClick={addProduct} style={{ float: "right" }}>
                Save
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddProduct;

