import {
    PlusOutlined,
    UploadOutlined
} from "@ant-design/icons";

import {
    Button,
    Card,
    Row,
    Col,
    Modal,
    message,
    Upload,
    Table,
    Select,
    Tabs
} from "antd";

import { useState } from "react";

var i = 0;

//const columns = [
//    {
//        title: 'Merchant Id',
//        dataIndex: 'MerchantId',
//        key: 'MerchantId',
//    },
//    {
//        title: 'DBA Name',
//        dataIndex: 'DbaName',
//        key: 'DbaName',
//    },
//    {
//        title: 'Residual',
//        dataIndex: 'Residual',
//        key: 'Residual',
//    },
//    {
//        title: 'Assign To',
//        key: ++i,
//        render: () => {
//            return (
//                <Select
//                    style={{
//                        width: '100%',
//                    }}
//                    placeholder="Please select"
//                    options={[
//                        { label: "Shaukat", value: "1", },
//                        { label: "Direct", value: "2", },
//                        { label: "Mickey", value: "3", },
//                    ]}
//                />
//            );
//        }
//    },
//];
const SelectBox = () => {
    return (
        <Select
            style={{
                width: '100%',
            }}
            placeholder="Please select"
            options={[
                { label: "Shaukat", value: "1", },
                { label: "Direct", value: "2", },
                { label: "Mickey", value: "3", },
            ]}
        />
    );
}
const commonColumns = {
    merchantId: {
        title: 'Merchant Id',
        dataIndex: 'MerchantId',
        key: 'MerchantId',
    },
    dbaName: {
        title: 'DBA Name',
        dataIndex: 'DbaName',
        key: 'DbaName',
    },
    quantity: {
        title: 'Quantity',
        dataIndex: 'Quantity',
        key: 'Quantity',
    },
    revenue: {
        title: 'Revenue',
        dataIndex: 'Revenue',
        key: 'Revenue',
    },
    residual: {
        title: 'Residual',
        dataIndex: 'Residual',
        key: 'Residual',
    },
    assignTo: {
        title: 'Assign To',
        key: ++i,
        render: () => <SelectBox />
    }
}
const columns = {
    debitCards: [
        commonColumns.merchantId,
        commonColumns.dbaName,
        {
            title: 'Transactions',
            dataIndex: 'Transactions',
            key: 'Transactions',
        },
        {
            title: 'Expense',
            dataIndex: 'Expense',
            key: 'Expense',
        },
        commonColumns.quantity,
        commonColumns.revenue,
        {
            title: 'Shared Revenue',
            dataIndex: 'SharedRevenue',
            key: 'SharedRevenue',
        },
        commonColumns.residual,
        commonColumns.assignTo
    ],
    equipments: [
        commonColumns.merchantId,
        commonColumns.dbaName,
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: 'Expense',
            dataIndex: 'Expense',
            key: 'Expense',
        },
        commonColumns.quantity,
        commonColumns.revenue,
        {
            title: 'Shared Revenue',
            dataIndex: 'SharedRevenue',
            key: 'SharedRevenue',
        },
        commonColumns.residual,
        commonColumns.assignTo
    ],
    hardwareSoftwares: [
        commonColumns.merchantId,
        commonColumns.dbaName,
        {
            title: 'Charge Type',
            dataIndex: 'ChargeType',
            key: 'ChargeType',
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
        },
        commonColumns.quantity,
        commonColumns.revenue,
        {
            title: 'Shared Revenue',
            dataIndex: 'SharedRevenue',
            key: 'SharedRevenue',
        },
        commonColumns.residual,
        commonColumns.assignTo
    ],
    miscellaneous: [
        commonColumns.merchantId,
        commonColumns.dbaName,
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
        },
        {
            title: 'Expense',
            dataIndex: 'Expense',
            key: 'Expense',
        },
        commonColumns.quantity,
        commonColumns.revenue,
        {
            title: 'Shared Revenue',
            dataIndex: 'SharedRevenue',
            key: 'SharedRevenue',
        },
        commonColumns.residual,
        commonColumns.assignTo
    ]
}

const ManageProcessors = () => {

    const props = {
        name: 'file',
        action: window.SiteBasePath + `/api/processor/process`,
        headers: {
            authorization: "bearer " + localStorage.getItem("AppAuthToken") || "",
        },
        onChange(info) {
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                setDataSource(info.file.response)
            }
            else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataSource, setDataSource] = useState({
        Miscellaneous: [],
        DebitCards : [],
        Equipments : [],
        HardwareSoftwares: [],
        Softwares: []
    });

    return (
        <>
            <h4 style={{ margin: "10px 0" }}>Manage Processors</h4>
            <Card style={{ marginBottom: "10px" }}>
                <Row>
                    <Col span={4}>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            size="large"
                            onClick={() => {
                                setDataSource([]);
                                setIsModalOpen(true);
                            }}
                        >
                            Process Global Payments Sheet
                        </Button>
                    </Col>
                </Row>
            </Card>

            <Modal title="Upload Sheet" visible={isModalOpen}
                onOk={() => setIsModalOpen(false)}
                onCancel={() => setIsModalOpen(false)}
                width="1000px"
            >
                <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>

                <p style={{ margin: "20px 0" }}>
                    <b>New Merchants:</b>
                </p>
                <Tabs>
                    <Tabs.TabPane tab="DebitCards" key="item-1">
                        <Table
                            dataSource={dataSource.DebitCards}
                            pagination={false}
                            columns={columns.debitCards}
                            bordered
                            size="small"
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Equipments" key="item-2">
                        <Table
                            dataSource={dataSource.Equipments}
                            pagination={false}
                            columns={columns.equipments}
                            bordered
                            size="small"
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="HardwareSoftwares" key="item-3">
                        <Table
                            dataSource={dataSource.HardwareSoftwares}
                            pagination={false}
                            columns={columns.hardwareSoftwares}
                            bordered
                            size="small"
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Miscellaneous" key="item-4">
                        <Table
                            dataSource={dataSource.Miscellaneous}
                            pagination={false}
                            columns={columns.miscellaneous}
                            bordered
                            size="small"
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Softwares" key="item-5">
                        <Table
                            dataSource={dataSource.Softwares}
                            pagination={false}
                            columns={columns.hardwareSoftwares}
                            bordered
                            size="small"
                        />
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
        </>
    );
};

export default ManageProcessors;
