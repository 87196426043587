import { MenuOutlined } from "@ant-design/icons";
import moment from "moment";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  List,
  Menu,
  Modal,
  Row,
  Table,
  Timeline,
} from "antd";
import { useEffect, useState } from "react";
import {
  DateFormat,
  fetchData,
  postData,
  showConfirm,
  showError,
  toLocalDateTime,
  toUtc,
} from "../../utils";
import { Parser } from "json2csv";
import _ from "lodash";
import { useBlockScreen, useRoot, useSetActiveTab } from "../../rootContext";
const getColumns = ({ onAction }) => [
  {
    title: "Order Number",
    dataIndex: "OrderNumber",
    key: "OrderNumber",
    fixed: "left",
    render: (x, item) => (
      <a
        onClick={(e) => {
          e.preventDefault();
          onAction({ action: "VIEW_ORDER", item });
        }}
      >
        {x}
      </a>
    ),
  },
  {
    title: "Channel",
    dataIndex: "Channel",
    key: "Channel",
    fixed: "left",
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    fixed: "left",
  },
  {
    title: "Supplier",
    children: [
      {
        title: "Name",
        dataIndex: "Supplier",
        key: "Supplier",
      },
      {
        title: "Order Number",
        dataIndex: "SupplierOrder",
        key: "SupplierOrder",
      },
    ],
  },
  {
    title: "Purchase Date",
    dataIndex: "PurchaseDate",
    key: "PurchaseDate",
    render: (x, item) => toLocalDateTime(item.PurchaseDate),
  },
  {
    title: "Modified Date",
    dataIndex: "Modified",
    key: "Modified",
    render: (x, item) => toLocalDateTime(item.Modified),
  },
  {
    title: "Action",
    dataIndex: "Id",
    key: "x",
    render: (x, item) =>
      ActionMenue({
        onClick: (action) => {
          //console.log({ action, item });
          onAction({ action, item });
        },
      }),
  },
];
const ActionMenue = (props) => {
  return (
    <Dropdown overlay={menu(props)} placement="bottomLeft">
      <Button>
        <MenuOutlined />
      </Button>
    </Dropdown>
  );
};
const menu = (props) => {
  return (
    <Menu
      items={[
        {
          key: "DELETE",
          label: "Delete",
          onClick: (e) => {
            props.onClick(e.key);
          },
        },
        // {
        //   disabled: !!props.scheduled,
        //   key: "SCHEDULE",
        //   label: "Schedule for update",
        //   onClick: (e) => {
        //     props.onClick(e.key);
        //   },
        // },
      ]}
    />
  );
};

const Orders = () => {
  const setActiveTab = useSetActiveTab();
  const blocker = useBlockScreen();
  const root = useRoot();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingData] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [pageData, setPageData] = useState({ data: [], total: 0 });
  const [filterData, setFilterData] = useState({
    dateFrom: moment().add(-30, "days").format(DateFormat),
    dateTo: moment().format(DateFormat),
  });
  const [pageInfo, setPageInfo] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [form] = Form.useForm();

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const clearFilter = () => {
    const newFilter = {
      dateFrom: moment().add(-30, "days").format(DateFormat),
      dateTo: moment().format(DateFormat),
    };
    setFilterData({ ...newFilter });
    getData({ page: 0, size: 10, filter: newFilter });
  };

  const applyFilter = () => {
    getData({ page: 0, size: 10, filter: filterData });
  };

  const getData = ({ page, size, filter }) => {
    fetchData(
      window.SiteBasePath +
        `/api/orders?page=${page || 0}&size=${size || 10}&dateFrom=${
          toUtc(filter.dateFrom) || ""
        }&dateTo=${toUtc(filter.dateTo, true) || ""}`,
      { blocker, root }
    ).then((resp) => {
      setPageData({ data: resp.Data, total: resp.Count });

      setSelectedRowKeys([]);
    });
  };

  useEffect(() => {
    getData({
      page: pageInfo.current - 1,
      size: pageInfo.pageSize,
      filter: filterData,
    });
  }, [pageInfo]);

  useEffect(() => {
    setActiveTab("orders");
  }, []);

  const actionMenueProps = {
    onClick: (action) => {
      const items = pageData.data.filter(
        (x) => selectedRowKeys.indexOf(x.Id) > -1
      );
      //console.log({ items, selectedRowKeys, data: pageData.data });
      onAction({ action, items });
    },
  };

  const onAction = ({ action, item, items }) => {
    if (!item && (!items || items.length < 1)) {
      showError("Please select an item first");
      return;
    }
    if (!items) items = [];
    if (item) items.push(item);

    if (action === "SCHEDULE") {
      scheduleItems(items);
    } else if (action === "DELETE") {
      showConfirm("Are you sure you want to delete these order(s)?", () => {
        deleteItems(items);
      });
    } else if (action === "VIEW_ORDER") {
      setModalData(item);
      setModalVisible(true);
    }
    setSelectedRowKeys([]);
  };
  const scheduleItems = (items) => {
    const ids = items.map((x) => x.Id);
    postData(window.SiteBasePath + `/api/orders/ChangeStatus?status=10`, ids, {
      blocker,
      root,
    }).then((resp) => {
      applyFilter();
    });
  };
  const deleteItems = (items) => {
    const ids = items.map((x) => x.Id);
    postData(window.SiteBasePath + `/api/orders/ChangeStatus?status=99`, ids, {
      blocker,
      root,
    }).then((resp) => {
      applyFilter();
    });
  };

  const exportAll = () => {
    fetchData(
      window.SiteBasePath +
        `/api/orders/for-csv?dateFrom=${
          toUtc(filterData.dateFrom) || ""
        }&dateTo=${toUtc(filterData.dateTo, true) || ""}&skus=${
          filterData.skus || ""
        }`,
      { blocker, root }
    ).then((resp) => {
      ceateCsv(resp.Data);
    });
  };
  const exportData = () => {
    ceateCsv(pageData.data);
  };
  console.log(modalData);
  const placeOrder = async (item) => {
    fetchData(
      window.SiteBasePath + `/api/orders/place-order?orderId=${item.Id}`,
      { blocker, root }
    ).then(() => {
      setModalVisible(false);
      setModalData(null);
      applyFilter();
    });
  };
  return (
    <>
      <h4 style={{ margin: "10px 0" }}>Orders</h4>
      <Card style={{ marginBottom: "10px" }}>
        <Form form={form} layout="vertical">
          <Row>
            <Col span={4}>
              <Form.Item label="Start Date">
                <DatePicker
                  value={filterData.dateFrom ? moment(filterData.dateFrom) : ""}
                  onChange={(date) => {
                    let d = date ? date.format(DateFormat) : "";
                    setFilterData({
                      ...filterData,
                      dateFrom: d,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="End Date">
                <DatePicker
                  value={filterData.dateTo ? moment(filterData.dateTo) : ""}
                  onChange={(date) => {
                    let d = date ? date.format(DateFormat) : "";
                    setFilterData({
                      ...filterData,
                      dateTo: d,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            {/* <Col span={6}>
              <Form.Item label="Skus">
                <Input
                  value={filterData.skus}
                  placeholder="0000, 1111, ..."
                  onChange={(e) => {
                    setFilterData({ ...filterData, skus: e.target.value });
                  }}
                />
              </Form.Item>
            </Col> */}
            <Col span={6} style={{ display: "none", marginLeft: "12px" }}>
              <Form.Item label="Field A">
                <Input placeholder="input placeholder" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ textAlign: "left" }}>
              <Button onClick={exportData}>Export</Button>
              <Button onClick={exportAll}>Export All</Button>
              <ActionMenue {...actionMenueProps} />
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button onClick={clearFilter}>Reset</Button>
              <Button onClick={applyFilter}>Search</Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card style={{ marginBottom: "10px" }}>
        <Table
          loading={loadingData}
          rowKey="Id"
          pagination={{ total: pageData.total }}
          onChange={(pagination) => {
            setPageInfo({ ...pagination });
          }}
          expandable={{
            expandedRowRender: (record) => (
              <p
                style={{
                  margin: 0,
                }}
              >
                <strong>Error: </strong>
                {record.ErrorMessage}
              </p>
            ),
          }}
          rowSelection={rowSelection}
          columns={getColumns({ onAction })}
          dataSource={pageData.data}
          bordered
          size="small"
        />
      </Card>
      <Modal
        getContainer=".custom-spin-class .ant-spin-container"
        zIndex={3}
        title="Order Detail"
        visible={modalVisible}
        width={800}
        footer={[
          <Button
            disabled={modalData && modalData.Status !== "New"}
            key="back"
            type="primary"
            onClick={() => placeOrder(modalData)}
          >
            Place Order
          </Button>,
        ]}
        onCancel={() => setModalVisible(false)}
      >
        {!!modalData ? (
          <>
            <List
              size="small"
              bordered
              dataSource={[
                <>
                  <strong>Order Number: </strong>
                  {modalData.OrderNumber}
                </>,
                <>
                  <strong>Channel: </strong>
                  {modalData.Channel}
                </>,
                <>
                  <strong>Status: </strong>
                  {modalData.Status}
                </>,
                <>
                  <strong>Supplier: </strong>
                  {modalData.Supplier}
                </>,
                <>
                  <strong>Supplier Order Number: </strong>
                  {modalData.SupplierOrder}
                </>,
              ]}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            ></List>
            <p style={{ marginTop: "25px" }}>
              <strong>Items: </strong>
            </p>
            <Table
              dataSource={modalData.Items}
              columns={[
                {
                  title: "Sku",
                  dataIndex: "ProductSku",
                  key: "ProductSku",
                },
                {
                  title: "Title",
                  dataIndex: "ProductTitle",
                  key: "ProductTitle",
                },
                {
                  title: "Price",
                  dataIndex: "Price",
                  key: "Price",
                },
                {
                  title: "Tax",
                  dataIndex: "Tax",
                  key: "Tax",
                },
              ]}
              pagination={false}
            />

            <p style={{ textAlign: "center", marginTop: "25px" }}>
              <strong>History</strong>
            </p>
            <Timeline mode="left">
              {modalData.History.map((item) => (
                <Timeline.Item
                  color="green"
                  label={toLocalDateTime(item.Created)}
                >
                  {item.Status}
                </Timeline.Item>
              ))}
            </Timeline>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};
const ceateCsv = (data) => {
  const fields = [
    {
      label: "Sku",
      value: "Sku",
    },
    {
      label: "Scansource Qty",
      value: "SsAvailability",
    },
    {
      label: "Scansource Price",
      value: "SsPrice",
    },
    {
      label: "Synnex Qty",
      value: "SnxAvailability",
    },
    {
      label: "Synnex Price",
      value: "SnxPrice",
    },
    {
      label: "Bluestar Qty",
      value: "BsAvailability",
    },
    {
      label: "Bluestar Price",
      value: "BsPrice",
    },
    {
      label: "Bluestar Msrp",
      value: "BsMsrp",
    },
    {
      label: "Ingram Qty",
      value: "IngAvailability",
    },
    {
      label: "Ingram Retail Price",
      value: "IngRetPrice",
    },
    {
      label: "Ingram Customer Price",
      value: "IngCustPrice",
    },
    {
      label: "Updated On",
      value: "Modified",
    },
  ];
  const json2csvParser = new Parser({ quote: "", fields });
  const csv = json2csvParser.parse(data);

  const filename = "pna_" + Date.now() + ".csv";
  const blob = new Blob([csv], { type: "text/csv" });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
};

export default Orders;
