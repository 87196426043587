import moment from "moment";
import React, { useContext, useState } from "react";
import { mapUserPermissions } from "../src/utils";

var RootContext = React.createContext(null);

export const useRoot = () => useContext(RootContext);
export const useSetActiveTab = () => {
    const { setActiveTab } = useContext(RootContext);
    return (tabKey) => setActiveTab(tabKey);
};
export const useBlockScreen = () => {
    const { setScreenBlocked } = useContext(RootContext);
    return {
        block: () => setScreenBlocked(true),
        unblock: () => setScreenBlocked(false),
    };
};

export const useAuth = () => {
    const { setLogedIn, setUser } = useContext(RootContext);
    return {
        logout: () => {
            localStorage.removeItem("AppAuthToken");
            localStorage.removeItem("LoginData");
            setLogedIn(false);
            setUser({});
        },
        login: ({ UserName, Email, permissions }) => {
            setLogedIn(true);
            setUser({ UserName, Email, permissions });
        },
    };
};

export const RootProvider = ({ children }) => {
    let userInfo = {};
    let hasToken = false;
    let data = localStorage.getItem("LoginData");
    if (data) {
        data = JSON.parse(data);
        if (data.expiration && data.token) {
            if (moment(data.expiration) > moment.utc()) {
                userInfo = {
                    ...data, permissions: mapUserPermissions(data)
                };
                hasToken = true;
                localStorage.setItem("AppAuthToken", data.token);
            }
        }
    }

    const [activeTab, setActiveTab] = useState("");
    const [screenBlocked, setScreenBlocked] = useState(false);
    const [logedIn, setLogedIn] = useState(hasToken);
    const [user, setUser] = useState(userInfo);
    return (
        <RootContext.Provider
            value={{
                activeTab,
                setActiveTab,
                screenBlocked,
                setScreenBlocked,
                logedIn,
                setLogedIn,
                user,
                setUser,
            }}
        >
            {children}
        </RootContext.Provider>
    );
};
